import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[6];

const Biddulph = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Michelle M. Biddulph" profile_key="biddulph">
      <Meta site={siteMetadata} title="Michelle M. Biddulph" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Michelle represents clients charged with criminal and quasi-criminal offences, as well as professional discipline matters. Her practice includes both trials and appeals, with a particular focus on appellate advocacy. She has appeared before all levels of court in Ontario and Saskatchewan, as well as the Federal Court, the Nova Scotia Court of Appeal, and the Supreme Court of Canada. </p>

				<p>Prior to joining the firm in 2017, Michelle served as a law clerk to Justice Russell Brown and Justice Marshall Rothstein of the Supreme Court of Canada. She also practiced criminal law as an associate lawyer at a large firm in her home province of Saskatchewan. Michelle completed her articles as a law clerk to Chief Justice Richards, Justice Lane, and Justice Ryan-Froslie of the Saskatchewan Court of Appeal. She was called to the bar in 2015.</p>

				<p>Michelle is the sole author and co-author of numerous academic articles published in law journals across Canada, the United States, and Australia. Her writing has focused on topics in constitutional law, criminal law, administrative law, international law, and Aboriginal law.</p>

				<p>She is a member of the bar in Saskatchewan and Ontario. She is also a member of the Canadian Bar Association; the Ontario Bar Association; the Criminal Lawyers’ Association; the Advocates’ Society; and the Toronto Lawyers’ Association.</p>

				<p>Michelle received a Bachelor of Arts (Honours) from the University of Saskatchewan in 2011, with a major in International Cooperation and Conflict. She attended the University of Saskatchewan College of Law, receiving her Juris Doctor with Great Distinction in 2014. Michelle was the recipient of various scholarships and awards while in law school, including the Law Society of Saskatchewan Silver Medal; the Ron Fritz Prize; the Amy and Brian Pfefferle Prize (in Criminal Procedure and Evidence); the Douglas Schmeiser Prize in Constitutional Law; the Thomas P. Deis/Cronkite Prize in Constitutional Law; the Donald MacLean Memorial Prize in Constitutional Law; and the William Elliott Scholarship. She competed in the Jessup Moot in 2013 and 2014, winning a number of prizes for oral and written advocacy. </p>

			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default Biddulph
